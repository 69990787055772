import React from "react";
import useSWR from "swr";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import { get } from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useBaseData } from "context/BaseDataProvider";
import SectionGrid from "components/SectionGrid/SectionGrid";
import Image from "components/UI/Image/Image";
import Box from "components/UI/Box/Box";
import Text from "components/UI/Text/Text";
import Heading from "components/UI/Heading/Heading";
import Container from "components/UI/Container/Container";
import ReactMultiCarousel from "components/UI/ReactMultiCarousel/ReactMultiCarousel";

const getImageDimensions = (meta = {}) => {
  const width = get(meta, "width");
  const height = get(meta, "height");
  const wUnits = get(meta, "wUnits");
  const hUnits = get(meta, "hUnits");

  const result = {};
  if (width && wUnits) {
    result.width = width;
    result.widthInUnit = [width].join("");
  }
  if (height && hUnits) {
    result.height = height;
    result.heightInUnit = [height].join("");
  }

  if (width && height) {
    result.aspectRatio = `${width}/${height}`;
  }

  return result;
};

const responsive = (array = [], defaultWhenEmpty = []) => {
  let previous;
  const values =
    array?.map((item) => {
      const isNullEmptyOrUndefined = typeof item === "undefined" || item === null || item === ""
      previous =
      isNullEmptyOrUndefined ? previous : item;
      return previous;
    }) || [];
  if (!values.filter(Boolean).length) {
    return defaultWhenEmpty;
  }
  return values;
};

const CommonPage = ({ pageCode = "" }) => {
  if (!pageCode) {
    return <></>;
  }
  return <Page pageCode={pageCode} />;
};

const Page = ({ pageCode = "" }) => {
  const { data: page, isValidating } = useSWR(
    `${process.env.REACT_APP_API_URL}/page?code=${pageCode}`
  );

  const isLoading = !page && isValidating;

  if (isLoading) {
    return <></>;
  }

  const { pageSections = [], siteSections = [] } = get(page, "0", {});
  return (
    <>
      {pageSections.map((pageSection, index) => (
        <Box
          key={index}
          m={responsive(get(pageSection, "margin"), [0])}
          bg={get(pageSection, "bg") || "#ffffff"}
        >
          <Container noGutter fluid={get(pageSection, "fluid", true)}>
            <Section section={siteSections[index]} />
          </Container>
        </Box>
      ))}
    </>
  );
};

const Section = ({ section }) => {
  const { getAssetUrl } = useBaseData();

  if (section.componentType === "SectionHeading") {
    return (
      <Box px={[4, 5]} pt={4} pb={2}>
        <Heading
          fontSize={[20, 24, 28]}
          content={section.configuration.heading}
          textAlign={section.configuration.align || "left"}
        />
        <Text
          content={section.configuration.subHeading}
          textAlign={section.configuration.align || "left"}
        />
      </Box>
    );
  }
  if (section.componentType === "Banner") {
    const gutter = responsive(get(section, "configuration.gutter"));
    const column = responsive(get(section, "configuration.column"));
    return (
      <Row
        gutter={{
          xs: gutter[0],
          sm: gutter[1],
          md: gutter[2],
          lg: gutter[3],
          xl: gutter[4],
          xxl: gutter[5],
        }}
      >
        {section.components.map((component, index) => (
          <Col
            key={index}
            xs={column[0]}
            sm={column[1]}
            md={column[2]}
            lg={column[3]}
            xl={column[4]}
            xxl={column[5]}
          >
            <LinkTo to={component.linkTo}>
              <Image
                as={LazyLoadImage}
                alt=""
                width="100%"
                style={{
                  aspectRatio: getImageDimensions(component.photo[0]?.meta)
                    .aspectRatio,
                }}
                src={getAssetUrl(component.photo[0]?.path)}
                p={responsive(get(section, "configuration.imagePadding"), 0)}
                borderRadius={responsive(
                  get(section, "configuration.imageRadius"),
                  0
                )}
              />
            </LinkTo>
          </Col>
        ))}
      </Row>
    );
  }

  if (section.componentType === "PhotoGrid") {
    const configuration = get(section, "configuration", {});
    return (
      <SectionGrid
        data={section.components.map((item, index) => ({ ...item, id: index }))}
        limit={section.components.length}
        columnWidth={responsive(get(configuration, "columnWidth"), [0.5, 0.2])}
        renderComponent={(component) => (
          <LinkTo to={component.linkTo}>
            <Box
              flexBox
              flexDirection="column"
              justifyContent="center"
              p={responsive(get(configuration, "padding"), 0)}
            >
              <Image
                as={LazyLoadImage}
                alt=""
                width="100%"
                style={{
                  aspectRatio: getImageDimensions(component.photo[0]?.meta)
                    .aspectRatio,
                }}
                src={getAssetUrl(component.photo[0]?.path)}
                p={responsive(get(configuration, "imagePadding"), 0)}
                borderRadius={responsive(get(configuration, "imageRadius"), 0)}
              />
              <Text
                textAlign="center"
                fontWeight={responsive(get(configuration, "fontWeight"), 300)}
                fontSize={responsive(get(configuration, "fontSize"), 14)}
                content={component.name}
                mt={1}
              />
            </Box>
          </LinkTo>
        )}
      />
    );
  }

  if (section.componentType === "Carousel") {
    const itemsPerView = responsive(
      get(section, "configuration.itemsPerView"),
      []
    );
    const infinite =
      ["true", true].includes(get(section, "configuration.infinite")) || false;
    const autoPlaySpeed = get(section, "configuration.autoPlaySpeed") || 0;
    const transitionDuration =
      get(section, "configuration.transitionDuration") || 0;

    return (
      <ReactMultiCarousel
        id={section.id}
        arrows={true}
        showDots={true}
        autoPlay={Number(autoPlaySpeed) > 0}
        autoPlaySpeed={Number(autoPlaySpeed)}
        transitionDuration={Number(transitionDuration)}
        draggable={true}
        swipeable={true}
        infinite={infinite}
        focusOnSelect={false}
        renderDotsOutside={false}
        responsive={{
          xxl: { items: itemsPerView[5] },
          xl: { items: itemsPerView[4] },
          lg: { items: itemsPerView[3] },
          md: { items: itemsPerView[2] },
          sm: { items: itemsPerView[1] },
          xs: { items: itemsPerView[0] },
        }}
      >
        {section.components.map((component, index) => (
          <LinkTo key={index} to={component.linkTo}>
            <Box p={responsive(get(section, "configuration.imagePadding"), 0)}>
              <Image
                as={LazyLoadImage}
                alt=""
                width="100%"
                style={{
                  aspectRatio: getImageDimensions(component.photo[0]?.meta)
                    .aspectRatio,
                }}
                src={getAssetUrl(component.photo[0]?.path)}
                borderRadius={responsive(
                  get(section, "configuration.imageRadius"),
                  0
                )}
              />
            </Box>
          </LinkTo>
        ))}
      </ReactMultiCarousel>
    );
  }
  return null;
};

const LinkTo = ({ to = "", children }) => {
  const defaultLinkTo = `${window.location.pathname}${window.location.search}`;
  if (to.indexOf("http", 0) !== -1) {
    return (
      <a href={to || defaultLinkTo} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
  return <Link to={to || defaultLinkTo}>{children}</Link>;
};

export default CommonPage;
