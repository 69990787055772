import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

import { useBaseData } from 'context/BaseDataProvider';
import {
  HOME_PAGE,
  ABOUT_US_PAGE,
  PRIVACY_POLICY_PAGE,
  REFUND_POLICY_PAGE,
  TERMS_OF_USE_PAGE,
} from 'settings/constant';
import Text from 'components/UI/Text/Text';

const FooterMenu = () => {
  const {
    preferences: { shiprocketTrackingUrl },
  } = useBaseData();
  return (
    <Menu>
      <Menu.Item key="0">
        <NavLink to={`${HOME_PAGE}`}><Text content="Home" fontWeight={300} fontSize={["7px","8px","9px","14px"]}/></NavLink>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink to={`${ABOUT_US_PAGE}`}><Text content="About Us" fontWeight={300} fontSize={["7px","8px","9px","14px"]}/></NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to={`${PRIVACY_POLICY_PAGE}`}><Text content="Privacy Policy" fontWeight={300} fontSize={["7px","8px","9px","14px"]}/></NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink to={`${REFUND_POLICY_PAGE}`}><Text content="Refund Policy" fontWeight={300} fontSize={["7px","8px","9px","14px"]}/></NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <NavLink to={`${TERMS_OF_USE_PAGE}`}><Text content="Terms of Use" fontWeight={300} fontSize={["7px","8px","9px","14px"]}/></NavLink>
      </Menu.Item>
      <Menu.Item key="5">
        <a target="_blank" rel="noopener noreferrer" href={shiprocketTrackingUrl}><Text content="Track your order" fontWeight={300} fontSize={["7px","8px","9px","14px"]}/></a>
      </Menu.Item>
    </Menu>
  );
};

export default FooterMenu;
