import React from "react";

import { useBaseData } from "context/BaseDataProvider";
import Logo from "components/UI/Logo/Logo";
import Footer from "components/Footer/Footer";
import FooterMenu from "./FooterMenu";
import Text from "components/UI/Text/Text";

const FooterSection = () => {
  const {
    preferences: { fullLogoUrl },
  } = useBaseData();

  return (
    <Footer
      logo={<Logo withLink linkTo="/" src={fullLogoUrl} />}
      menu={<FooterMenu />}
      copyright={
        <Text
          content={`© ${new Date().getFullYear()} Diskounto Group. All rights reserved`}
          fontWeight={300}
          fontSize={["7px", "8px", "9px", "14px"]}
        />
      }
    />
  );
};

export default FooterSection;
