import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const CardWrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  box-shadow: 0 6px 12px ${themeGet("boxShadow.2", "rgba(0, 0, 0, 0.05)")};

  @media only screen and (max-width: 575px) {
    margin-bottom: 0px;
  }

  &:hover {
    box-shadow: 0 6px 12px ${themeGet("boxShadow.2", "rgba(0, 0, 0, 0.16)")};

    .content_wrapper {
      border-color: transparent;
    }

    .react-multiple-carousel__arrow {
      opacity: 1;
      visibility: visible;
    }

    .react-multi-carousel-dot-list {
      bottom: 0;
    }

    .action-button {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const ActionButton = styled.div`
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  position: absolute;
  bottom: 0;
  width: 80%;
  margin: 0 10%;
  margin-bottom: 10px;
  padding: 10px;
  background: white;
  text-align: center;
  border: 1px solid;
  border-color: ${themeGet("border.3", "#E6E6E6")};
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
`;

export const FallBackMessage = styled.div`
  opacity: 0.9;
  position: absolute;
  bottom: 0;
  width: 80%;
  margin: 0 10%;
  margin-bottom: 10px;
  padding: 10px;
  color: indianred;
  background: white;
  text-align: center;
  border: 1px solid;
  border-color: ${themeGet("border.3", "#E6E6E6")};
`;

export const ImageWrapper = styled.div`
  position: relative;
  > img {
    max-width: 100%;
    height: auto;
  }

  .react-multi-carousel-list {
    background-color: #e9e8ec;
  }

  .react-multiple-carousel__arrow {
    top: 0;
    width: 22%;
    height: 100%;
    border-radius: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 1;

    &::before {
      font-weight: 700;
    }
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
    background: linear-gradient(
      to left,
      transparent 0%,
      rgba(0, 0, 0, 0.1) 100%
    );
    &:hover {
      background: linear-gradient(
        to left,
        transparent 0%,
        rgba(0, 0, 0, 0.1) 100%
      );
    }
  }

  .react-multiple-carousel__arrow--right {
    right: 0;
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(0, 0, 0, 0.1) 100%
    );
    &:hover {
      background: linear-gradient(
        to right,
        transparent 0%,
        rgba(0, 0, 0, 0.1) 100%
      );
    }
  }

  .react-multi-carousel-dot-list {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      rgba(0, 0, 0, 0.25) 100%
    );
    transition: bottom 0.3s ease;
  }

  .react-multi-carousel-dot {
    align-items: center;

    button {
      width: 6px;
      height: 6px;
      border: 0;
      background-color: ${themeGet("color.3", "#E9E8E8")};
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
      transition: all 0.3s ease;
    }
  }

  .react-multi-carousel-dot--active {
    button {
      width: 8px;
      height: 8px;
      background-color: ${themeGet("color.1", "#ffffff")};
    }
  }
`;

export const ContentWrapper = styled.div`
  padding: 15px;
  cursor: pointer;
`;

export const BrandArea = styled.div`
  color: ${themeGet("text.0", "#2C2C2C")};
  font-size: 15px;
  font-weight: 700;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (max-width: 767px) {
    font-size: 10px;
  }
`;

export const TitleArea = styled.div`
  color: ${themeGet("text.0", "#2C2C2C")};
  font-size: 15px;
  font-weight: 300;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (max-width: 767px) {
    font-size: 12px;
    margin: 2px 0;
  }

  a {
    color: ${themeGet("text.0", "#2C2C2C")};
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 2px;
    @media only screen and (max-width: 767px) {
      font-size: 12px;
      margin: 2px 0;
    }
    &:hover {
      color: ${themeGet("primary.0", "#008489")};
    }
  }
`;

export const MetaWrapper = styled.div`
  position: relative;
  transition: all 0.3s ease-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PriceArea = styled.div`
  color: ${themeGet("text.0", "#2C2C2C")};
  font-size: 12px;
  font-weight: 300;
  display: flex;
  align-items: center;
  & .discount {
    color: ${themeGet("primary.0", "#008489")};
  }
`;

export default CardWrapper;
