import React from "react";
import PropTypes from "prop-types";
import CardWrapper, {
  ImageWrapper,
  ContentWrapper,
  BrandArea,
  TitleArea,
  MetaWrapper,
  PriceArea,
  ActionButton,
  FallBackMessage,
} from "./ProductCardContent.style";
import Box from "components/UI/Box/Box";

const ProductCardContent = ({
  className,
  brand,
  productName,
  price,
  showActionButton = true,
  showFallbackMessage = false,
  fallbackMessage = "OUT OF STOCK",
  actionBtnText = "ADD TO CART",
  onActionButtonClick = () => {},
  onContentClick = () => {},
  children,
  styleProps = {}
}) => {
  let classes = actionBtnText ? `has_btn ${className}` : className;
  return (
    <Box as={CardWrapper} className={`grid_card ${classes}`.trim()} {...styleProps}>
      <ImageWrapper className="media_wrapper">
        {children}
        {showActionButton && (
          <ActionButton
            className="action-button"
            onClick={(e) => {
              e.preventDefault();
              onActionButtonClick();
            }}
          >
            {actionBtnText}
          </ActionButton>
        )}
        {showFallbackMessage && 
          <FallBackMessage>
            {fallbackMessage}
          </FallBackMessage>
        }
      </ImageWrapper>

      <ContentWrapper className="content_wrapper" onClick={onContentClick}>
        {brand && <BrandArea>{brand}</BrandArea>}
        {productName && <TitleArea>{productName}</TitleArea>}
        <MetaWrapper className="meta_wrapper">
          <PriceArea>{price}</PriceArea>
        </MetaWrapper>
      </ContentWrapper>
    </Box>
  );
};

ProductCardContent.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  editBtn: PropTypes.element,
  viewDetailsBtn: PropTypes.element,
};

export default ProductCardContent;
